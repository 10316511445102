import React, {
  useCallback,
} from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  Box,
  CardActions, CardContent,
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import {
  GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline,
} from 'react-google-login';

import { useAppDispatch } from '@/core/store/store';
import { TextField } from '@/core/components/TextField';

import { addGlobalMessage } from '@/features/GlobalMessage/store';
import { GlobalMessageType } from '@/features/GlobalMessage/interfaces';

import { redirect } from '@/utils/helpers';

import {
  LoginFormButton,
  LoginFormCard,
  useStyles,
} from './LoginForm.styles';
import {
  googleLogin,
  isUserLoggingIn, login,
} from '../store';
import { GoogleRequestPayload } from '../interfaces';

const { publicRuntimeConfig } = getConfig() || {};
const { GOOGLE_CLIENT_ID } = publicRuntimeConfig || process.env;

const schema = yup.object().shape({
  password: yup.string().min(3)
    .max(8)
    .required(),
  username: yup.string()
    .required(),
});

type onSubmitProps = (data: {
  password: string;
  username: string;
}) => void;

const GOOGLE_LOGIN_ONLY = true;

export const LoginForm = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const isLoggingIn = useSelector(isUserLoggingIn);

  const {
    register, handleSubmit, formState: { errors }, reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit: onSubmitProps = useCallback(async data => {
    try {
      unwrapResult(await dispatch(login(data)));
      reset();
      redirect(null, '/dashboard');
    } catch (error) {
      if (error instanceof Error) {
        dispatch(addGlobalMessage({
          message: error?.message,
          type: GlobalMessageType.ERROR,
        }));
      }
    }
  }, [dispatch, reset]);

  const handleGoogleLoginSuccess = useCallback(
    async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
      if ('code' in response) {
        const { code = '' } = response;

        try {
          const payload: GoogleRequestPayload = {
            authenticationUrl: window.location.origin,
            code,
          };

          const redirectUri = router.query?.next ?
            router.query.next as string :
            '/dashboard';

          unwrapResult(await dispatch(googleLogin(payload)));
          reset();
          redirect(null, redirectUri);
        } catch {
          dispatch(addGlobalMessage({
            message: 'Something went wrong during login',
            type: GlobalMessageType.ERROR,
          }));
        }
      } else {
        dispatch(addGlobalMessage({
          message: 'Provided bad response type',
          type: GlobalMessageType.ERROR,
        }));
      }
    }, [dispatch, reset]
  );

  const handleGoogleLoginError = useCallback(e => {
    // pass
  }, []);

  const classes = useStyles();

  return (
    <Box
      mt={4}
    >
      <form
        noValidate
        onSubmit={handleSubmit(onSubmit)}
      >
        <LoginFormCard>
          {!GOOGLE_LOGIN_ONLY && (
            <>
              <CardContent>
                <TextField
                  name="username"
                  type="text"
                  placeholder="Username"
                  error={errors?.username}
                  register={register}
                  disabled={isLoggingIn}
                />

                <TextField
                  name="password"
                  type="password"
                  placeholder="Password"
                  error={errors?.password}
                  register={register}
                  disabled={isLoggingIn}
                />
              </CardContent>
              <CardActions disableSpacing>
                <LoginFormButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={isLoggingIn}
                >
                  Login
                </LoginFormButton>
              </CardActions>
            </>
          )}

          {GOOGLE_LOGIN_ONLY && (
            <div className={classes.loginFormButtonWrapper}>
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                onFailure={handleGoogleLoginError}
                onSuccess={handleGoogleLoginSuccess}
                responseType="code"
                accessType="offline"
                prompt="consent"
              />
            </div>
          )}

        </LoginFormCard>
      </form>
    </Box>
  );
};
