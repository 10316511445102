import {
  Button,
  Card,
  makeStyles,
  withStyles,
} from '@material-ui/core';

export const LoginFormCard = withStyles({
  root: {
    margin: '0 auto',
    maxWidth: '500px',
  },
})(Card);

export const LoginFormButton = withStyles({
  root: {
    margin: '8px',
    width: '100%',
  },
})(Button);

export const useStyles = makeStyles({
  form: {
    border: '1px solid #e6e6e6',
    borderRadius: '10px',
    boxShadow: '0 4px 26px -17px #656565',
    margin: '0 auto',
    padding: '25px 30px',
    width: '290px',
  },
  loginFormButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px auto',
  },
});
