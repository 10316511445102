import { Component } from 'react';

import { wrapper } from '@/core/store/store';

import { Auth } from '@/features/Auth';

import { redirect } from '@/utils/helpers';

class HomePage extends Component {
  static isRestricted = false;

  static getInitialProps = wrapper.getInitialPageProps(store => ({
    res,
  }) => {
    const { auth } = store.getState();

    if (auth.user.id) redirect(res, '/dashboard');

    return {};
  });

  render() {
    return (
      <Auth />
    );
  }
}

export default HomePage;
